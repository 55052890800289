import validate from './validate'
import http from '@/utils/request'
export * from '@/utils/type'

// import numeral from 'numeral'
// import numeralUtil from '@/utils/numeral'

// 表单序列化
export const serialize = data => {
    let list = []
    Object.keys(data).forEach(ele => {
        list.push(`${ele}=${data[ele]}`)
    })
    return list.join('&')
}
export const getObjType = obj => {
    var toString = Object.prototype.toString
    var map = {
        '[object Boolean]': 'boolean',
        '[object Number]': 'number',
        '[object String]': 'string',
        '[object Function]': 'function',
        '[object Array]': 'array',
        '[object Date]': 'date',
        '[object RegExp]': 'regExp',
        '[object Undefined]': 'undefined',
        '[object Null]': 'null',
        '[object Object]': 'object'
    }
    if (obj instanceof Element) {
        return 'element'
    }
    return map[toString.call(obj)]
}
/**
 * 对象深拷贝
 */
export const deepClone = data => {
    var type = getObjType(data)
    var obj
    if (type === 'array') {
        obj = []
    } else if (type === 'object') {
        obj = {}
    } else {
        // 不再具有下一层次
        return data
    }
    if (type === 'array') {
        for (var i = 0, len = data.length; i < len; i++) {
            obj.push(deepClone(data[i]))
        }
    } else if (type === 'object') {
        for (var key in data) {
            obj[key] = deepClone(data[key])
        }
    }
    return obj
}
/**
 * 判断路由是否相等
 */
export const diff = (obj1, obj2) => {
    delete obj1.close
    var o1 = obj1 instanceof Object
    var o2 = obj2 instanceof Object
    if (!o1 || !o2) { /*  判断不是对象  */
        return obj1 === obj2
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false
        // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
    }

    for (var attr in obj1) {
        var t1 = obj1[attr] instanceof Object
        var t2 = obj2[attr] instanceof Object
        if (t1 && t2) {
            return diff(obj1[attr], obj2[attr])
        } else if (obj1[attr] !== obj2[attr]) {
            return false
        }
    }
    return true
}
/**
 * 设置灰度模式
 */
export const toggleGrayMode = (status) => {
    if (status) {
        document.body.className = document.body.className + ' grayMode'
    } else {
        document.body.className = document.body.className.replace(' grayMode', '')
    }
}
/**
 * 设置主题
 */
export const setTheme = (name) => {
    document.body.className = name
}

/**
 *加密处理
 */
export const encryption = (params) => {
    // let {
    //     data,
    //     type,
    //     param,
    //     key
    // } = params
    // const result = JSON.parse(JSON.stringify(data))
    // if (type === 'Base64') {
    //     param.forEach(ele => {
    //         result[ele] = btoa(result[ele])
    //     })
    // } else {
    //     param.forEach(ele => {
    //         var data = result[ele]
    //         key = CryptoJS.enc.Latin1.parse(key)
    //         var iv = key
    //         // 加密
    //         var encrypted = CryptoJS.AES.encrypt(
    //             data,
    //             key, {
    //                 iv: iv,
    //                 mode: CryptoJS.mode.CBC,
    //                 padding: CryptoJS.pad.ZeroPadding
    //             })
    //         result[ele] = encrypted.toString()
    //     })
    // }
    // return result
    return params

}

/**
 * 浏览器判断是否全屏
 */
export const fullscreenToggel = () => {
    if (fullscreenEnable()) {
        exitFullScreen()
    } else {
        reqFullScreen()
    }
}
/**
 * esc监听全屏
 */
export const listenfullscreen = (callback) => {
    function listen() {
        callback()
    }

    document.addEventListener("fullscreenchange", function () {
        listen()
    })
    document.addEventListener("mozfullscreenchange", function () {
        listen()
    })
    document.addEventListener("webkitfullscreenchange", function () {
        listen()
    })
    document.addEventListener("msfullscreenchange", function () {
        listen()
    })
}
/**
 * 浏览器判断是否全屏
 */
export const fullscreenEnable = () => {
    return document.isFullScreen || document.mozIsFullScreen || document.webkitIsFullScreen
}

/**
 * 浏览器全屏
 */
export const reqFullScreen = () => {
    if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen()
    } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen()
    } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
    }
}
/**
 * 浏览器退出全屏
 */
export const exitFullScreen = () => {
    if (document.documentElement.requestFullScreen) {
        document.exitFullScreen()
    } else if (document.documentElement.webkitRequestFullScreen) {
        document.webkitCancelFullScreen()
    } else if (document.documentElement.mozRequestFullScreen) {
        document.mozCancelFullScreen()
    }
}
/**
 * 递归寻找子类的父类
 */

export const findParent = (menu, id) => {
    for (let i = 0; i < menu.length; i++) {
        if (menu[i].children.length != 0) {
            for (let j = 0; j < menu[i].children.length; j++) {
                if (menu[i].children[j].id == id) {
                    return menu[i]
                } else {
                    if (menu[i].children[j].children.length != 0) {
                        return findParent(menu[i].children[j].children, id)
                    }
                }
            }
        }
    }
}

/**
 * 动态插入css
 */

export const loadStyle = url => {
    const link = document.createElement('link')
    link.type = 'text/css'
    link.rel = 'stylesheet'
    link.href = url
    const head = document.getElementsByTagName('head')[0]
    head.appendChild(link)
}
/**
 * 判断路由是否相等
 */
export const isObjectValueEqual = (a, b) => {
    let result = true
    Object.keys(a).forEach(ele => {
        const type = typeof (a[ele])
        if (type === 'string' && a[ele] !== b[ele]) {
            result = false
        } else if (type === 'object' && JSON.stringify(a[ele]) !== JSON.stringify(b[ele])) {
            result = false
        }
    })
    return result
}
/**
 * 根据字典的value显示label
 */
export const findByvalue = (dic, value) => {
    let result = ''
    if (validate.validatenull(dic)) {
        return value
    }
    if (typeof (value) === 'string' || typeof (value) === 'number' || typeof (value) === 'boolean') {
        let index = 0
        index = findArray(dic, value)
        if (index != -1) {
            result = dic[index].label
        } else {
            result = value
        }
    } else if (value instanceof Array) {
        result = []
        let index = 0
        value.forEach(ele => {
            index = findArray(dic, ele)
            if (index != -1) {
                result.push(dic[index].label)
            } else {
                result.push(value)
            }
        })
        result = result.toString()
    }
    return result
}
/**
 * 根据字典的value查找对应的index
 */
export const findArray = (dic, value) => {
    for (let i = 0; i < dic.length; i++) {
        if (dic[i].value == value) {
            return i
        }
    }
    return -1
}
/**
 * 生成随机len位数字
 */
export const randomLenNum = (len, date) => {
    let random = ''
    random = Math.ceil(Math.random() * 100000000000000).toString().substr(0, len || 4)
    if (date) {
        random = random + Date.now()
    }
    return random
}
/**
 * 指定长度随机字符串
 * @param {*} len
 * @returns
 */
export function randomLenStr(len) {
    len = len || 32;
    // let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';    /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
    let $chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789';
    let maxPos = $chars.length;
    let pwd = '';
    for (let i = 0; i < len; i++) {
        pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
    }
    return pwd;
}

/**
 * 打开小窗口
 */
export const openWindow = (url, title, w, h) => {
    // Fixes dual-screen position                            Most browsers       Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height

    const left = ((width / 2) - (w / 2)) + dualScreenLeft
    const top = ((height / 2) - (h / 2)) + dualScreenTop
    const newWindow = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left)

    // Puts focus on the newWindow
    if (window.focus) {
        newWindow.focus()
    }
}

/**
 *  <img> <a> src 处理
 * @returns {PromiseLike<T | never> | Promise<T | never>}
 */
export function handleImg(fileName, id) {
    return validate.validatenull(fileName) ? null : http.get({
        url: '/admin/file/' + fileName,
        responseType: 'blob'
    }).then((response) => { // 处理返回的文件流
        let blob = response.data
        let img = document.getElementById(id)
        img.src = URL.createObjectURL(blob)
        window.setTimeout(function () {
            window.URL.revokeObjectURL(blob)
        }, 0)
    })
}

export const filterForm = (form) => {
    let obj = {}
    Object.keys(form).forEach(ele => {
        if (!validate.validatenull(form[ele])) {
            obj[ele] = form[ele]
        }
    })
    return obj
}

export const vaildData = (val, dafult) => {
    if (typeof val === 'boolean') {
        return val
    }
    return !validate.validatenull(val) ? val : dafult
}


export const isObjectValueEqualJson = (a, b) => {
    var aProps = Object.getOwnPropertyNames(a)
    var bProps = Object.getOwnPropertyNames(b)
    if (aProps.length != bProps.length) {
        return false
    }
    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i]

        var propA = a[propName]
        var propB = b[propName]
        if ((typeof (propA) === 'object')) {
            if (isObjectValueEqualJson(propA, propB)) {
                return true
            } else {
                return false
            }
        } else if (propA !== propB) {
            return false
        } else {
            return true
        }
    }
}


export const recursive = (arr, arrKeywrord, id, childrenArr) => {
    let name = ""

    function fn(arr, id) {
        let flag = false
        for (let i in arr) {
            if (arr[i].id == id) {
                name = arr[i].name
                flag = true
            }
        }

        if (!flag) {
            for (let j in arr) {
                let text = fn(arr[j][childrenArr], id)
                console.log(1111111111111111)
            }
        }
    }

    fn(arr, id, childrenArr)
    return name
}



export const unique = (arr) => {

    var result = arr.reduce(function (prev, element) {

        if (!prev.find(el => el.buyerId == element.buyerId)) {
            prev.push(element)
        }
        return prev
    }, [])
    return result
}

/**
 *
 * @param {*} fmt 格式化方式
 * @param {*} date 日期时间
 */
export const dateFormat = (fmt, date) => {
    date = new Date(date)
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt;
}


/**
 * 下载文件
 * @param {*} data 文件数据
 * @param {*} fileName 文件名称
 */
export const exportFileName = (data, fileName) => {
    const content = data.data;
    const blob = new Blob([content]);
    if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
    } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
    }
}

export function isEmpty(value) {
    if (Array.isArray(value)) {
        return value.length < 1
    }
    if (Object.prototype.toString.call(value) === "[object Object]") {
        return Object.keys(value).length < 1
    }
    return value === undefined || value === null || value.toString().trim() === "";
}


/**
 * 路由path比较时去除最后的反斜杠
 * 防止路由后面多个/导致出现相同的路由比对不上的问题
 * @param {String} path
 * @returns
 */
export function rmBackslash(path) {
    if (path && path.length <= 1) {
        return path
    }
    return path.endsWith('/') ? path.substring(0, path.length - 1) : path
}



export const createUniqueId = () => {
    let str = '';
    str = Math.random().toString(36).substr(3);
    str += Date.now().toString(16).substr(4);
    return str;
}

function getInstancesName(opts) {
    return opts && (opts.Ctor.options.name || opts.tag)
}

export function getComponentName(view, index) {
    let name = "";
    // 获取匹配的组件叶子节点
    // TODO: 如果页面存在多个子组件子路由的情况是怎样
    let matched = view.matched[index || (view.matched.length - 1)];
    try {
        // 存在第一次进入页面组件实例还未完成创建
        name = getInstancesName(matched.instances.default.$vnode.componentOptions);
    } catch (error) {
        // 取组件内声明的名字
        try {
            name = matched.components.default.name || view.name
        } catch (error) {
            console.error("KeepAliveError: Component name not found!");
        }

    }
    return name;
}

/**
 * 加法运算，不处理小数位数，同时避免数据小数点精度损失
 * @param num1 加数1
 * @param num2 加数2
*/
export function numAdd(num1, num2) {
    var baseNum, baseNum1, baseNum2;
    try {
        baseNum1 = num1.toString().split(".")[1].length;
    } catch (e) {
        baseNum1 = 0;
    }
    try {
        baseNum2 = num2.toString().split(".")[1].length;
    } catch (e) {
        baseNum2 = 0;
    }
    baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
    return (num1 * baseNum + num2 * baseNum) / baseNum;
}

/**
 * 减法运算，不处理小数位数，同时避免数据小数点精度损失
 * @param num1 被减数
 * @param num2 减数
*/
export function numSub(num1, num2) {
    var baseNum, baseNum1, baseNum2;
    var precision;// 精度
    try {
        baseNum1 = num1.toString().split(".")[1].length;
    } catch (e) {
        baseNum1 = 0;
    }
    try {
        baseNum2 = num2.toString().split(".")[1].length;
    } catch (e) {
        baseNum2 = 0;
    }
    baseNum = Math.pow(10, Math.max(baseNum1, baseNum2));
    precision = (baseNum1 >= baseNum2) ? baseNum1 : baseNum2;
    return ((num1 * baseNum - num2 * baseNum) / baseNum).toFixed(precision);
}

/**
 * 字符串Hash值
 * @param {*} input
 * @returns
 */
function hash(str) {
    var h = 5003;
    for (var i = 0; i < str.length; ++i) {
        h += str.charCodeAt(i);
        h += (h << 10);
        h ^= (h >> 6);
    }
    h += (h << 3);
    h ^= (h >> 11);
    h += (h << 15);
    return Math.abs(h);
}

/**
 * @function 计算数值的差
 * @param {Number,String} minuend 被减数
 * @param {Number,String} subtrahend 减数
 * @param isCurrencyPrecision 是否转换国家配置的小数位
 * @param isThousandsFormat 是否转为千分格式  例：99,999
 * @returns className 元素类名 diffGreen diffRed
 * @returns content 转换后的展示结果
 * @returns diffRes 差值原数
 * @author Lexie Wen 2022-06-17
*/
// export function getPriceDiff(_minuend, _subtrahend, isThousandsFormat = true, isCurrencyPrecision = true) {
//     let className = ''
//     let content = '0'
//     let diffRes = null

//     if ( isEmpty(_minuend) ||  isEmpty(_subtrahend)) {
//         return { className: '', content: '--' }
//     }
//     const minuend = numeral(_minuend)
//     const subtrahend = numeral(_subtrahend)

//     // 差  minuend - subtrahend
//     let diffValue = numSub(minuend.value(), subtrahend.value())
//     if( isCurrencyPrecision ){
//         diffValue = diffValue.toFixed(this.countryCurrencyPrecision)
//     }
//     diffRes = diffValue
//     diffValue = diffValue.toString().replace('-','')

//     // 会强制去除小数
//     // if( isThousandsFormat ){
//     //     diffValue = numeral(diffValue).format('0,0').replace('-','')
//     // }
//     if ( diffRes > 0 || diffRes < 0) {
//         className = diffRes > 0 ? 'diffGreen' : 'diffRed'
//         content = diffRes > 0 ? '+' + diffValue : '-' + diffValue
//     }
//     return {
//         diffRes,
//         className,
//         content
//     }
// }


export const isMobile = () => {
    return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
}

/**
 * Copy handle
 * @param {*} text
 * @returns Promise
 */
export const CopyHandle = async (text) => {
    if(!text) { return Promise.reject(); }

    try {
        await navigator.clipboard.writeText(text);
    } catch (error) {
        const pElem = document.createElement('p');
        pElem.innerHTML = text;
        pElem.style.opacity = 0
        // append body 后，影响页面布局，要缩小
        pElem.style.display= 'inline-block'
        pElem.style.width = '1px'
        pElem.style.height = '1px'
        pElem.style.overflow = 'hidden'
        pElem.style.position = 'fixed'
        pElem.style.left = '10000vw'
        document.body.appendChild(pElem);

        const range = document.createRange();
        window.getSelection().removeAllRanges();
        range.selectNode(pElem);
        window.getSelection().addRange(range);

        const copyStatus = document.execCommand('Copy')
        if(copyStatus){
            return Promise.resolve(true)
        }else{
            return Promise.reject(false);
        }
    }
    return Promise.resolve(true)
}

export const getJson = (data) =>{
    try {
        return JSON.parse(data || "{}")
    } catch (error) {
        console.warn('getJson-error:', error);
        return {}
    }
}

export const debounce = (func, wait) => {
    let timer;
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(context, args);
        }, wait);
    }
}