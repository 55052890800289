/**
 * 自动将\n转为br
 */
const nl2br = {
    bind(el, binding) {
        el.innerHTML = binding.value.replace(/\n/g, '<br>');
    },
    update(el, binding) {
        el.innerHTML = binding.value.replace(/\n/g, '<br>');
    }
}

export default nl2br