<!--
 * @Descripttion:
 * @version:
 * @Author: chenzhongjie
 * @Date: 2023-09-04 14:29:35
 * @LastEditors: chenzhongjie
 * @LastEditTime: 2023-09-04 14:43:41
-->
<template>
	<div class="loadingBox">
        <div class='loading' ref="loading"></div>
    </div>
</template>

<script>
import lottie from 'lottie-web'
import egateeLoading from '@/assets/lottieJson/egateeLoading.json'
export default {
    name: 'loading',
    props: {},
    data() {
        return {
            anim: null
        }
    },
    mounted() {
        this.anim = lottie.loadAnimation({
            container: this.$refs.loading,
            animationData: egateeLoading,
            autoplay: true,
        })
    },
    watch: {},
    methods: {}
}
</script>
<style lang='scss' scoped>
.loadingBox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    .loading {
        width: 200px;
        display: inline-block;
    }
}
</style>
