import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import store from './store'
import project from '@/utils/project'
import {Chart} from '@/components/charts'
import VueClipboard from 'vue-clipboard2'
import 'babel-polyfill'
import 'resize-observer-polyfill/dist/ResizeObserver.global'
import { Lazyload } from 'vant';
// import Es6Promise from 'es6-promise'
// Es6Promise.polyfill()

// import VeLine from 'v-charts/lib/line.common'
// Vue.component(VeLine.name, VeLine)
// import VCharts from 'v-charts-v2'
// Vue.use(VCharts)


// 引入echarts
// import echarts from "./lib/echarts";

// 挂载到vue实例中
// Vue.prototype.$echarts = echarts

// 全局注册自定义指令
import CustomerDirectives from './directives/index.js'
Vue.use(CustomerDirectives)


Vue.component('vue-frappe', Chart)
Vue.use(VueClipboard)

Vue.use(Lazyload);

Vue.config.productionTip = false

import '@/components/index'
import './vantComponents';

import './rem.js' // postcss 根节点字体大小设置

import '@/permission' // permission control
import i18n from './lang'
import './style/fonts/iconfont.css'
import './style/vant.scss'
import './style/flex.css'
import './style/global.css'
import './style/reset.scss'

new Vue({
    router,
    i18n,
    store,
    project,
    render: h => h(App),
}).$mount('#app')
