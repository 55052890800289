import Vue from 'vue'
import { mapGetters, mapState } from "vuex"

Vue.mixin({
    beforeCreate() {
        const options = this.$options
        if (options.project) {
            this.$project = options.project;
        } else if (options.parent && options.parent.$project) {
            this.$project = options.parent.$project
        }
    },
    computed: {
        ...mapState({
            countryInfo: state => state.user.countryInfo,
            // countryTimeZone: state => state.user.countryTimeZone,
            countryCurrencySymbol: state => state.user.countryCurrencySymbol,
            
            // countryCurrencyPrecision: state => state.user.countryCurrencyPrecision,
        }),
        // 权限标识
        ...mapGetters([
            "permissions"
        ])
    },
    methods: {
        
    }
})
