import observeExposure from "./observeExposure.js";
import nl2br from './nl2br.js'

const CustomerDirectives = {
    install(Vue) {
        Vue.directive("observe-exposure", observeExposure);
        Vue.directive("nl2br", nl2br);
    }
}

export default CustomerDirectives