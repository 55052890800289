<!--
 * @Descripttion: 空白缺省图
 * @version:
 * @Author: chenzhongjie
 * @Date: 2023-09-19 16:12:04
 * @LastEditors: chenzhongjie
 * @LastEditTime: 2023-09-19 16:14:20
-->
<template>
    <div class="temporarilyNodata">
        <img src="@/assets/default-no.png" />
        <template v-if="$slots.desc">
            <slot name="desc"></slot>
        </template>
        <p v-else>{{ desc }}</p>
    </div>
</template>
<script>
export default {
    name: 'temporarilyNodata',
    props: {
        desc: {
            type: String,
            default: 'Temporarily no data'
        }
    }
}
</script>
<style lang="scss" scoped>
.temporarilyNodata {
	margin: 100px auto;
	img {
		width: 280px;
		height: 160px;
		display: block;
		margin: 20px auto;
	}
	p {
		text-align: center;
		font-size: 30px;
		font-family: Roboto;
		font-weight: 400;
		color: #000000;
		line-height: 43px;
	}
}
</style>